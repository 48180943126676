* {
  --experiment-base: #0030f3;
  --experiment-base-2: #ffffff;
  --experiment-dark: #131313;
  --experiment-mid: #464646;
  --experiment-light: #ababab;
  --experiment-proficiency: #2aecbb;
  --experiment-frequency: #a51fec;
  --experiment-enjoyment: #ff7709;
  --experiment-border-radius: 15px;
  --experiment-border-radius-tiny: 5px;
  --experiment-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1), 0 4px 5px 0 rgba(0, 0, 0, 0.1);
}

.experiment-container {
  font-size: 0.8em;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 30s ease infinite;
  min-height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

h1 {
  margin: 0;
  font-size: 3em;
  padding: 30px 0 0 0;
  color: white;
  text-align: center;
}

h2 {
  font-size: 2em;
}

.main-inputs {
  display: flex;
}

.main-input {
  padding: 10px;
  margin: 10px;
  width: calc(90% - 20px);
  border: none;
  box-shadow: none;
}

.main-input h3 {
  color: white;
  margin-bottom: 5px;
  margin-left: 5px;
}

.main-input textarea {
  border-radius: var(--experiment-border-radius);
  width: calc(100% - 20px);
  min-height: 100px;
  padding: 10px;
}

.person {
  background-color: var(--experiment-base-2);
  margin: 50px;
  padding: 20px;
  border-radius: var(--experiment-border-radius);
}

@media only screen and (max-width: 700px) {
  .person {
    margin: 10px;
  }
}

.person-name {
  text-align: center;
}

.csv-input {
  width: 100%;
  min-height: 100px;
}

.thing {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 700px) {
  .thing {
    margin-bottom: 20px;
  }
}

.thing-aspects {
  max-width: 40%;
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 700px) {
  .thing-aspects {
    margin-left: 40px;
    padding-left: 40px;
    border-left: 1px solid var(--experiment-light);
  }
}

.thing-title {
  text-align: right;
  margin-right: 20px;
  width: 30%;
  font-size: 1.2em;
}

@media only screen and (max-width: 700px) {
  .thing-title {
    width: 100%;
  }
}

.score-element-title {
  margin: 5px;
}

.score-element {
  border-radius: var(--experiment-border-radius-tiny);
  color: white;
  font-size: 1.2em;
  font-weight: 600;
  background-color: var(--experiment-dark);
  padding: 5px;
  display: flex;
  margin: 2px;
}

.score-element input {
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
  max-width: 30px;
  border: none;
  border-radius: var(--experiment-border-radius-tiny);
  text-align: center;
  color: var(--experiment-mid);
}


.score-element__Proficiency {
  background-color: var(--experiment-proficiency);
}

.score-element__Frequency {
  background-color: var(--experiment-frequency);
}

.score-element__Enjoyment {
  background-color: var(--experiment-enjoyment);
}

.aspects-to-render {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  background-color: white;
  z-index: 100;
}

.aspects-to-render-instruction {
  margin: 5px 10px;
  padding: 4px;
}

@media only screen and (max-width: 700px) {
  .aspects-to-render-instruction {
    flex-grow: 1;
    width: 100%;
  }
}

.select-aspect-to-render {
  margin: 5px 10px;
  padding: 4px 10px;
  color: white;
  border-radius: var(--experiment-border-radius-tiny);
  cursor: pointer;
}

.select-aspect-to-render__Proficiency {
  background-color: var(--experiment-proficiency);
}

.select-aspect-to-render__Frequency {
  background-color: var(--experiment-frequency);
}

.select-aspect-to-render__Enjoyment {
  background-color: var(--experiment-enjoyment);
}

.select-aspect-to-render__aspect-disabled {
  background-color: var(--experiment-light);
}

.graph-container {
  background-color: white;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  border-radius: var(--experiment-border-radius);
  border: 1px solid var(--experiment-light);
}

.graph-container svg {
  padding: 10px 0;
  width: 100%;
}

.graph-container .scale {
  stroke-width: 0.6;
}

.graph-container g {
  width: 100%;
}

.experiment-caption {
  z-index: 1000000;
}

.things-and-breakdowns {
  margin: 0 20px;
  padding-bottom: 100px;
}

@media only screen and (max-width: 700px) {
  .things-and-breakdowns {
    margin: 0 10px;
  }
}

.breakdown-title {
  margin-top: 20px;
  text-align: center;
  color: white;
}

.load-example-button {
  margin: 10px 0px;
  padding: 20px;
  color: white;
  border-radius: var(--experiment-border-radius-tiny);
  cursor: pointer;
  background-color: var(--experiment-dark);
}

.tidbit {
  display: flex;
  justify-content: space-between;
  background-color: var(--experiment-frequency);
  box-shadow: var(--experiment-box-shadow);
  margin: 20px;
  padding: 20px;
  border-radius: var(--experiment-border-radius);
}

.tidbit-info {
  color: white;
}

.tidbit-title {
  font-size: 2em;
}

.tidbit-copy {
  margin-top: 10px;
  font-size: 1.2em;
}

.infomoji {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid white;
  padding-bottom: 2px;
}
